import request from '@/utils/request'


//末级指标概览
export function indicatorStatistic(data) {
  return request({
    url: '/api/statistic/indicator-statistic',
    method: 'post',
    data
  })
}

//任务统计
export function taskStatistic(data) {
  return request({
    url: `/api/statistic/task-statistic?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data
  })
}

//一级指标统计
export function topIndicatorStatistic(data) {
  let reqData = {
    url: `/api/statistic/top-indicator-statistic?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data
  };
  if (data.export == 1) {
    reqData.responseType = 'blob';
    reqData.timeout = 60000
  }
  return request(reqData)
}

//人员进度排行
export function employeeIndicatorStatistic(data) {
  let reqData = {
    url: `/api/statistic/employee-indicator-statistic?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data,
  };
  if (data.export == 1) {
    reqData.responseType = 'blob';
    reqData.timeout = 60000
  }
  return request(reqData)
}


//部门进度排行 保存部门配置
export function saveStatisticDepartment(data) {
  return request({
    url: '/api/statistic/save-statistic-department',
    method: 'post',
    data
  })
}


//部门进度排行 获取部门配置
export function statisticDepartment(data) {
  return request({
    url: '/api/statistic/statistic-department',
    method: 'get',
    params: data
  })
}

//部门进度排行
export function departmentIndicatorStatistic(data) {
  let reqData = {
    url: `/api/statistic/department-indicator-statistic?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data
  };
  if (data.export == 1) {
    reqData.responseType = 'blob';
    reqData.timeout = 60000
  }
  return request(reqData)
}


//获取预警参数
export function getProcessWarningValue(data) {
  return request({
    url: '/api/company/get-process-warning-value',
    method: 'get',
    params: data
  })
}

//保存预警参数
export function editProcessWarningValue(data) {
  return request({
    url: '/api/company/edit-process-warning-value',
    method: 'post',
    data
  })
}
