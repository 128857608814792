import request from '@/utils/request'

export function getPostListByTree(data) {
  return request({
    url: '/api/post/list',
    method: 'post',
    data: data
  })
}


export function getList(data) {
  return request({
    url: '/api/post/list',
    method: 'get',
    params: data
  })
}

export function create(data) {
  return request({
    url: '/api/post/create',
    method: 'post',
    data: data
  })
}

export function edit(data) {
  return request({
    url: '/api/post/edit',
    method: 'post',
    data: data
  })
}

export function del(data) {
  return request({
    url: '/api/post/del',
    method: 'post',
    data: data
  })
}
