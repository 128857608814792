<template>
    <div class="department">
      <el-row class="fit" :gutter="14" type="flex">
        <el-col class="r-flex-1">
          <r-panel title="区域管理">
  
            <div class="search r-flex r-pb-10">
                <div class="r-flex-1">
                  <el-input placeholder="请输入区域名称" v-model="areaTreeKeyword" size="small" @input="areaTreeKeywordChange"
                            class="input-box">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input>
                </div>
              </div>
              <el-tree ref="areaTree" :data="areaTree" node-key="id" highlight-current :expand-on-click-node="false"
                       :default-expand-all="false" :props="{ label: 'name' }" :filter-node-method="filterNode"
                       v-loading="areaTreeLoading" :default-expanded-keys="areaDefaultExpandedKeys" @node-click="handleCurrentChange">
              </el-tree>
          </r-panel>
        </el-col>
        <el-col class="table-cover">
          <r-panel title="当前节点：">
            <template v-slot:title>
                <el-tag size="small" v-if="currentNode" :closable="true" @close="closeCurrentNode">
                    {{ currentNode.name }}
                </el-tag>
                <el-tag size="small" v-else>
                    暂无选择区域
                </el-tag>
            </template>
            <r-table height="100%" :columns="columns" :data="areaData" ref="table">
                <template v-slot:status="{row}">
                    <el-switch v-model="row.status" :disabled="row.loading" :active-value="1" :inactive-value="0" @change="handleStatusChange(row)"></el-switch>
                </template>
            </r-table>
          </r-panel>
        </el-col>
      </el-row>
      <update-dep ref="updateDep" @success="getDepByTree"></update-dep>
      <update-employee ref="updateEmployee" @success="refresh"></update-employee>
      <search ref="search" @depChange="setDep"></search>
      <roles ref="roles"></roles>
      <set-role ref="setRole"></set-role>
      <import-excel
          v-model="dialogImportExcel"
          type="TYPE_OWN"
          @refresh="refresh"
      ></import-excel>
    </div>
  </template>
  
  <script>
  import {uniq} from "@/utils";
  import employee from "@/mixins/employee";
  import {
    getDepByTree,
    setHideDepartment,
  } from "@/api/dep/index";
  import {fetchList as getStaffList} from "@/api/staff/index";
  import updateDep from "./components/update-dep";
  import updateEmployee from "./components/update-employee";
  import {getCityList,editArea} from "@/api/common";
  import {delList, exportList} from "@/api/employee/index";
  import search from "./components/search";
  import roles from "./components/roles";
  import setRole from "./components/set-role";
  import fileDownload from "js-file-download";
  import importExcel from "@/components/excel/importExcel.vue";
  
  export default {
    mixins: [employee],
    components: {updateDep, updateEmployee, search, roles, setRole, importExcel},
    data() {
      return {
        type: 'dep',
        treeKeyword: '',
        areaTreeKeyword: '',
        isHideDep: false,
        depTree: [],
        areaTree: [],
        defaultExpandedKeys: [],
        defaultExpandedKeysBak: [],
        areaDefaultExpandedKeys: [],
        treeLoading: false,
        areaTreeLoading: false,
        department: "",
        currentNode: null,
        subDep: false,
        keyword: '',
        exportLoading: false,
        dialogImportExcel: false,
        columns: [
          {
            label: '区域名称',
            prop: 'name',
            'min-width': '140px',
            'show-overflow-tooltip': true
          }, {
            label: '状态',
            prop: 'status',
            'width': '180px',
            'show-overflow-tooltip': true
          },{
            label: '创建时间',
            prop: 'createdAt',
            'width': '200px',
            'show-overflow-tooltip': true
          }
        ],
        areaData:[]
      }
    },
    methods: {
      areaTreeKeywordChange(val) {
        this.$refs.areaTree.filter(val);
      },
      treeKeywordChange(val) {
        this.$refs.tree.filter(val);
      },
      handleHideDep() {
        this.getDepByTree();
      },
      handleCurrentChange(data, node) {
        this.expandedKeys = [data.id];
        this.currentNode = node.data;
        this.getAreaData(node.data);
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      //节点展开
      nodeExpand(data) {
        this.defaultExpandedKeysBak.push(data.id);
        this.defaultExpandedKeysBak = uniq(this.defaultExpandedKeysBak);
      },
      //节点关闭
      nodeCollapse(data) {
        this.defaultExpandedKeysBak = this.defaultExpandedKeysBak.filter((el) => {
          return el != data.id;
        });
      },
      getDepByTree() {
        this.treeLoading = true;
        return getDepByTree({
          type: 1,
          formType: "system",
          isHideDep: this.isHideDep,
        }).then((response) => {
          if (response.data.length > 0) {
            if (!this.currentNode) {
              this.currentNode = response.data[0];
              this.$nextTick(() => {
                this.$refs?.tree?.setCurrentKey && this.$refs.tree.setCurrentKey(this.currentNode.id);
              })
            }
            if (this.defaultExpandedKeysBak.length == 0) {
              this.defaultExpandedKeys = [response.data[0].id];
            } else {
              this.defaultExpandedKeys = this.defaultExpandedKeysBak;
            }
          }
          this.depTree = response.data;
          this.treeLoading = false;
        });
      },
      //新建部门
      addDep() {
        this.$refs.updateDep.open();
      },
      //编辑
      editDep(data) {
        this.$refs.updateDep.open({
          id: data.id,
          name: data.name
        });
      },
      //隐藏部门
      handleHide(node) {
        this.$confirm(
            "此操作要" +
            (node.isHide === 1 ? "显示" : "隐藏") +
            "该部门, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
        ).then(() => {
          setHideDepartment({id: node.id}).then((response) => {
            this.getDepByTree();
            this.$message.success((node.isHide === 1 ? "显示" : "隐藏") + "成功");
          });
        }).catch(err => {
        });
      },
      //员工子部门切换
      handleSupDep() {
        this.$refs.table.refresh(1);
      },
      refresh() {
        this.$refs.table.refresh();
      },
  
      //员工列表请求
      async loader(page, pageSize) {
        let pid = this.currentNode?.id || '';
        return getListRegionByPage({
          isList:1,
          page,
          pageSize,
          pid,
        }).then((response) => {
          return response.data;
        });
      },
      //新增员工
      addEmployee() {
        this.$refs.updateEmployee.open(
            {
              department: this.currentNode ? [this.currentNode] : [],
              areaId: this.$store.state.userInfo?.company?.areaId == '00000000-0000-0000-0000-000000000000' || !this.$store.state.userInfo?.company?.areaId ? '' : this.$store.state.userInfo?.company?.areaId
            }
        );
      },
      //编辑员工
      editEmployee(row) {
        this.$refs.updateEmployee.open({
          employeeId: row.id,
          nickname: row.nickname,
          areaId: row.areaId,
          phone: row.phone,
          hidePhone: row.hidePhone,
          remarks: row.remarks,
          positionName: row.positionName,
          department: [row.dep],
          position: row.position,
          roleData: row.employeeRole?.length> 0 ? row.employeeRole.map(el=>el.roleId) : []
        }, true);
      },
      //删除员工
      delEmployee(row) {
        this.$confirm(
            `确定要删除员工 ${row.nickname}吗？`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
        ).then(() => {
          delList({employee_id: row.id}).then((response) => {
            this.refresh();
            this.$message.success("删除成功");
          });
        }).catch(err => {
        });
      },
      typeChange(type) {
        this.type = type;
      },
      getCityList() {
        this.areaTreeLoading = true;
        getCityList({status:[0,1]}).then(res => {
          //默认展开两级
          res.data.forEach(el => {
            this.areaDefaultExpandedKeys.push(el.id);
            el.children && el.children.forEach(_el => {
              this.areaDefaultExpandedKeys.push(_el.id);
            })
          })
          this.areaTree = res.data;
        }).finally(() => {
          this.areaTreeLoading = false;
        })
      },
      employeeSearch() {
        this.$refs.table.refresh(1);
      },
      setDep(id) {
        this.$refs.tree.setCurrentKey && this.$refs.tree.setCurrentKey(id);
        //获取当前节点
        this.currentNode = this.$refs.tree.getCurrentNode();
        this.$nextTick(() => {
          this.handleSupDep();
        })
      },
      searchOpen() {
        this.$refs.search.open();
      },
      rolesOpen() {
        this.$refs.roles.open();
      },
      setRole(row) {
        this.$refs.setRole.open({
          dialogTitle: '设置角色',
          isEdit: true,
          id: row.id
        });
      },
      exportExcel() {
        this.exportLoading = true;
        exportList({
          ...this.query,
        }).then((res) => {
          fileDownload(res, "组织架构.xlsx");
        }).finally((res) => {
          this.exportLoading = false;
        });
      },
      importExcel() {
        this.dialogImportExcel = true;
      },
      closeCurrentNode(){
        this.currentNode = null;
        this.$nextTick(()=>{
            this.areaData = [];
        })
      },
      getAreaData(item){
        this.areaData = item?.children || [];
      },
      handleStatusChange(row){
        this.$set(row,'loading',true);
        editArea({id:row.id,status:row.status}).then(res=>{
            this.$message.success(`${row.name}状态已${row.status === 1 ? '启用' : '停用'}`);
        })
        .catch(err=>{
            //修改失败切回原样
            row.status = row.status === 1 ? 0 : 1;
        })
        .finally(()=>{
            this.$set(row,'loading',false);
        })
      }
    },
    mounted() {
      this.getCityList();
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .department {
    height: 100%;
  
    .fit {
      height: 100%;
    }
  
    .table-cover {
      flex: 2.2;
      width: 0;
    }
  
    ::v-deep .sub-dep {
      margin-left: 20px;
  
      .el-checkbox__label {
        padding-left: 4px;
      }
    }
  
    .custom-tree-node {
      flex: 1;
      font-size: 14px;
      padding-right: 8px;
    }
  
    .dep-num {
      background-color: #3f85f4;
      color: #ffffff;
      font-size: 12px;
      min-width: 34px;
      display: inline-block;
      height: 18px;
      text-align: center;
      line-height: 18px;
      margin: 0 6px;
      border-radius: 3px;
    }
  
  
    .custom-tree-btn {
      visibility: hidden;
      padding-left: 10px;
  
      .btn {
        font-size: 16px;
      }
  
      &.show {
        visibility: visible;
      }
    }
  
    .hide-icon {
      font-size: 20px;
    }
  
  }
  </style>