<template>
    <div class="stack-line">
        <div class="stack-line-colors r-flex">
            <div class="stack-line-colors_item r-flex" v-for="color in colors" :key="color.prop"
                @click="closeItem(color)" :class="{ close: closeItems[color.prop] }">
                <div class="stack-line-color" :style="{ background: color.color }"></div>
                <div class="stack-line-colors_label">{{ color.label }}</div>
            </div>
        </div>
        <div class="stack-line-list">
            <stack-line-item :item="item" v-for="item in tableData" :key="item.id" :colors="colors"
                :closeItems="closeItems" :warningValue="warningValue"></stack-line-item>
        </div>
        <div class="stack-line-footer r-flex r-row-bet">
            <div class="stack-line-footer-toolbar">
                <slot name="footerToolbar"></slot>
            </div>
            <div class="stack-line-pagination" v-if="$attrs['layout'] || loader">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize" background
                    :layout="$attrs['layout'] || layout" :hide-on-single-page="false" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import stackLineItem from './stack-line-item'
export default {
    components: {
        stackLineItem
    },
    props: {
        loader: {
            type: Function,
            default: null
        },
        warningValue:{
            type:Number,
            default:0
        }
    },
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            colors: [],
            tableData: [],
            closeItems: {},
            total: 0,
            pageSizes: [10, 20, 30],
            layout: 'total, sizes, prev, pager, next',
        }
    },
    methods: {
        load() {
            if (!this.loader) return;
            this.loader(this.currentPage, this.pageSize).then(res => {
                this.colors = this.getColors(res.color);
                this.tableData = res.list;
                this.total = res.total;
            })
        },
        //刷新方法
        refresh(page) {
            this.currentPage = page || this.currentPage;
            this.load();
        },
        //用来处理一下颜色
        getColors(colors) {
            let list = colors.map(el => {
                let num = el.prop.replace(/[a-zA-Z]/g, '');
                return {
                    ...el,
                    num
                }
            });

            return list;
        },
        closeItem(item) {
            this.$set(this.closeItems, item.prop, !this.closeItems[item.prop]);
        },
        //切换一屏显示数量
        handleSizeChange(size) {
            this.pageSize = size;
            this.load();
        },
        //切换页码
        handleCurrentChange(page) {
            this.currentPage = page;
            this.load(true);
        },
        
    },
    mounted() {
        this.load();
    }

}
</script>

<style lang="scss" scoped>
.stack-line {
    &-colors {
        padding-bottom: 20px;

        &_label {
            padding-left: 5px;
            font-size: 13px;
            color: #666666;
        }

        &_item {
            margin-left: 20px;
            transition: opacity .2s;
            cursor: pointer;

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                opacity: .7;
            }

            &.close {
                .stack-line-color {
                    background: #ecebeb !important;
                }

                .stack-line-colors_label {
                    color: #c8c6c6;
                }
            }
        }
    }

    &-color {
        width: 30px;
        height: 20px;

    }

    &-colors_label,
    &-color {
        transition: all .3s;
    }
    .stack-line-footer{
        padding-top:30px;
    }
}
</style>