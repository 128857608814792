import filingsIndicator from "@/views/filings/indicator";
import filingsTaskList from "@/views/filings/task/list";
import filingsTask from "@/views/filings/task/index";
import filingsApprove from "@/views/filings/task/approve";
import filingsStatistics from "@/views/filings/statistics/index";
export default {
    filingsIndicator,
    filingsTaskList,
    filingsTask,
    filingsApprove,
    filingsStatistics
}
  