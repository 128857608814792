<template>
    <div>
        <r-dialog ref="rDialog" dialog-title="部门配置" size="1000px" @submit="submit" @close="close">
            <div class="save-dep-container r-flex" v-loading="loading" element-loading-text="正在提交..."
        element-loading-background="rgba(255,255, 255, 0.8)">
                <div class="r-flex-1 r-h-max r-border-right r-flex r-flex-column">
                    <div class="r-mb-10 r-w-max">部门架构</div>
                    <div class="scroll-view r-flex-auto-h r-w-max">
                        <el-tree 
                        ref="tree" 
                        :data="depTree" 
                        node-key="id" 
                        :expand-on-click-node="false"
                        :default-expand-all="true"
                        :props="{ label: 'name' }"
                        :filter-node-method="filterNode" 
                        v-loading="treeLoading" 
                        show-checkbox 
                        check-strictly
                        @check-change="checkChange">
                        <div class="custom-tree-node r-flex  r-row-bet" slot-scope="{ node, data }">
                            <div class="department-content r-line-1">
                                {{ node.label }}
                            </div>
                        </div>
                    </el-tree>
                    </div>
                </div>
                <div class="r-flex-2 r-h-max r-pl-10">
                    <r-table :data="selected" :columns="columns"  height="100%" drag :cell-class-name="addDragClass" @dragEnd="dragEnd">
                        <template v-slot:includingSub="{row}">
                            <el-checkbox v-model="row.includingSub" size="small" :true-label="1" :false-label="0">包含子部门数据</el-checkbox>
                        </template>      
                        <template v-slot:handle="{row}">
                            <el-link type="primary" @click="handleEdit(row)">删除</el-link>
                        </template>
                        <template v-slot:sort="{row}">
                            <div class="r-flex r-row-center"><r-icon name="rank"></r-icon></div>
                        </template>
                    </r-table>
                </div>
            </div>
        </r-dialog>
    </div>
</template>
<script>
import {
  getDepByTree
} from "@/api/dep/index";
import { statisticDepartment, saveStatisticDepartment } from '@/api/statistics';
export default {
    data(){
        return {
            treeLoading:false,
            depTree:[],
            selected:[],
            columns:[
                {
                    label:'排序',
                    prop:'sort',
                    'width':'60px'
                },
                {
                    label:'已选部门',
                    prop:'name'
                },
                {
                    label:'数据范围',
                    prop:'includingSub'
                },
                {
                    label:'操作',
                    prop:"handle",
                    'width':'80px'
                }
            ],
            loading:false
        }
    },
    methods: {
        addDragClass({row,column}){
            return column.property == 'sort' ? 'handle' : '';
        },
        close(){
            this.depTree = [];
            this.selected = [];
            this.loading = false;
            this.treeLoading = false;
        },
        open() {
            this.init();
            this.$refs.rDialog.handleOpen();
        },
        async init(){
            await this.getDep();
            this.statisticDepartment();
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        getDep(){
            this.treeLoading = true;
            getDepByTree({
                type: 1,
                formType: "system",
                isHideDep: 0,
            }).then(res => {
                this.depTree = res.data;    
            })
            .finally(()=>{
                this.treeLoading = false;
            })
        },
        statisticDepartment(){
            statisticDepartment({module: 'filings'}).then(res=>{
                this.selected = res.data;
                //设置选中
                this.$refs.tree.setCheckedKeys(res.data.map(item=>item.id),true,false);
            })
        },
        checkChange(data,check){
            if(check){
                if(this.selected.find(el=>el.id === data.id)) return;
                this.selected.push(data);
            }else{
                this.selected = this.selected.filter(item => item.id!== data.id);
            }
        },
        dragEnd(list,callback){
            this.selected = [];
            this.$nextTick(()=>{
                this.selected = list;
                callback && callback();
            })
        },
        handleEdit(row){
            this.selected = this.selected.filter(item => item.id!== row.id);
            //取消勾选
            this.$refs.tree.setChecked(row.id,false,false);
        },
        submit(done){
            let options = this.selected.map(el=>{
                return {
                    id:el.id,
                    includingSub:el?.includingSub ? 1 : 0
                }
            });
            this.loading = true;
            saveStatisticDepartment({departmentList:options, module: 'filings'}).then(res=>{
                this.$message.success('部门配置成功');
                this.$emit('change');
            }).finally(()=>{
                this.loading = false;
                done(true);
            })
        }
    }


}
</script>

<style lang="scss">
.save-dep-container {
    height: 60vh;
    padding: 20px 0 0;
    .custom-tree-node {
        flex: 1;
        font-size: 14px;
        padding-right: 8px;
    }
}
</style>