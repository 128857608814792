<template>
  <div class="department" @click="popHide">
    <el-row class="fit" :gutter="14" type="flex">
      <el-col class="r-flex-1">
        <r-panel :headerShow="false" :isScroll="false">

          <template>
            <div class="r-flex r-flex-column r-w-max r-h-max">
              <div class="search r-flex r-pb-10 r-w-max">
                <div class="r-w-max">
                  <el-input placeholder="请输入搜索内容" v-model="treeKeyword" size="small" @input="treeKeywordChange"
                    class="input-box" :clearable="true">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input>
                </div>
              </div>
              <div class="r-flex-auto-h scroll-view r-pt-5 r-pb-15 r-w-max">
                <el-tree ref="tree" :data="treeData" node-key="id" highlight-current :expand-on-click-node="false"
                  :default-expand-all="false" :default-expanded-keys="defaultExpandedKeys" :props="{ label: 'name' }"
                  @current-change="handleCurrentChange" :filter-node-method="filterNode" v-loading="treeLoading"
                  @node-expand="nodeExpand" @node-collapse="nodeCollapse">
                  <div class="custom-tree-node r-flex  r-row-bet" slot-scope="{ node, data }">
                    <div class="department-content r-line-1 r-flex-auto-w">
                      <span>{{ data | noFilter }}</span>&nbsp;
                      <span>{{ data.name }}</span>
                    </div>
                  </div>
                </el-tree>
              </div>
            </div>
          </template>
        </r-panel>
      </el-col>
      <el-col class="table-cover">
        <r-panel title="所属指标：" autoSize="left">
          <template v-slot:title>
            <div class="r-flex tags r-flex-auto-w">
              <div class="swiper r-w-max" id="taskListSwiper">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="tag-item hover--opacity">
                      <el-tag @click="breadcrumbClear">{{ companyName }}</el-tag>
                    </div>
                  </div>
                  <div class="swiper-slide" v-for="(item, index) in breadcrumb" :key="item.id">
                    <div class="tag-item hover--opacity">
                      <span class="r-pl-10 r-pr-10 r-opacity-3">/</span>
                      <el-tag @click="breadcrumbClick(item, index)">
                        {{ item.data.noLink.join('.') }}&nbsp;
                        {{ item.data.name }}
                      </el-tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:toolbar>
            <div class="r-flex">
              <el-popover placement="bottom-end" width="500" trigger="manual" v-model="popShow" @click.stop.native>
                <div>
                  <div class="r-pb-10">筛选条件</div>
                  <el-form size="small" label-position="right" label-width="110px">
                    <el-form-item label="负责人">
                      <r-tag-select v-model="searchQuery.employee" class="r-w-max" :collapseTags="true"
                        placeholder="请选择负责人" @focus="handleEmployee('checkbox', '请选择负责人', 'employee')"
                        labelKey="name"></r-tag-select>
                    </el-form-item>
                    <el-form-item label="协作人">
                      <r-tag-select v-model="searchQuery.collaborateEmployee" class="r-w-max" :collapseTags="true"
                        placeholder="请选择协作人" @focus="handleEmployee('checkbox', '请选择协作人', 'collaborateEmployee')"
                        labelKey="name"></r-tag-select>
                    </el-form-item>
                    <el-form-item label="部门">
                      <r-tag-select v-model="searchQuery.department" class="r-w-max" :collapseTags="true"
                        placeholder="请选择部门" @focus="handleChangeSelect('checkbox', '请选择负责部门', 'department')"
                        labelKey="name"></r-tag-select>
                    </el-form-item>
                    <el-form-item label="协作部门">
                      <r-tag-select v-model="searchQuery.collaborateDepartment" class="r-w-max" :collapseTags="true"
                        placeholder="请选择协作部门"
                        @focus="handleChangeSelect('checkbox', '请选择协作部门', 'collaborateDepartment')"
                        labelKey="name"></r-tag-select>
                    </el-form-item>
                    <el-form-item label="计划开始时间">
                      <el-date-picker class="r-w-max" v-model="searchQuery.startDate" type="daterange"
                        range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label="计划结束时间">
                      <el-date-picker class="r-w-max" v-model="searchQuery.endDate" type="daterange" range-separator="至"
                        value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label="任务状态">
                      <el-select v-model="searchQuery.status" placeholder="请选择任务状态" class="r-w-max" multiple>
                        <el-option v-for="item in statusList" :key="item.value" :value="item.value"
                          :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <div class="r-flex r-row-right">
                        <el-button @click="popHide">取消</el-button>
                        <el-button type="primary" @click="searchConfirm">确认</el-button>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
                <el-button slot="reference" class="r-mr-10 sx-btn" @click="popOpen" :class="{ active: searchActive }"
                  size="mini">
                  <r-icon name="shaixuan" type="task" size="16"></r-icon>
                </el-button>
              </el-popover>
              <el-input size="mini" placeholder="请输入任务名称关键字" v-model="query.keyword" clearable
                @keydown.enter.native="search"></el-input>
              <el-button size="mini" type="primary" class="r-ml-10" @click="search">查询</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" class="r-ml-10" @click="batchDel" v-if="$has('task-delete')" :loading="delLoading">批量删除<span v-if="selection.length">（已选：{{ selection.length }}条）</span></el-button>
              <el-button size="mini" type="success"  v-if="$has('task-edit')" icon="el-icon-edit-outline" class="r-ml-10" @click="batchEdit" :loading="delLoading">批量修改<span v-if="selection.length">（已选：{{ selection.length }}条）</span></el-button>
            </div>
          </template>
          <r-table height="100%" :columns="columns" :loader="loader" ref="table" :auto="false" @sortChange="sortChange"
            :cell-class-name="cellClassName" @cell-click="detailOpen" @selection-change="selectionChange" row-key="id">

            <template v-slot:handle="{ row }">
              <div v-if="!row.isTotal">
                <el-link type="primary" class="small" @click="editTask(row)" v-if="$has('task-edit')">编辑</el-link>
                <el-divider direction="vertical" v-if="$has('task-delete') && $has('task-edit')"></el-divider>
                <el-popconfirm title="确定要删除该任务吗？" @confirm="delTask(row)">
                  <el-link type="primary" slot="reference" v-if="$has('task-delete')">删除</el-link>
                </el-popconfirm>
              </div>
            </template>
            <template v-slot:[item.field]="{ row, column }" v-for="item in columnsComputeWay">
              <div :key="item.field">
                <div v-if="row.isTotal"
                  :class="{ 'hover--opacity': column.computeWay?.isTotal && column.computeWay?.isAvg }">
                  <el-dropdown v-if="column.computeWay?.isTotal && column.computeWay?.isAvg" trigger="click"
                    @command="(command) => { computeWayChange(item, command) }" class="r-w-max">
                    <p class="total-item r-flex r-row-bet">
                      <span>{{ item.value == 'isTotal' ? `合计：${row[item.field] || '0'}` : `平均：${row[item.field] || '0'}`
                        }}</span>
                      <span class="r-pl-10">
                        <r-icon name="caret-bottom" size="16"></r-icon>
                      </span>
                    </p>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="isTotal">合计</el-dropdown-item>
                      <el-dropdown-item command="isAvg">平均</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <p v-else class="total-item">
                    <span v-if="column.txt">{{ `${column.txt}${row[item.field] || '0'}` }}</span>
                    <span v-else>{{ `合计：${row[item.field] || '0'}` }}</span>
                  </p>
                </div>
                <p v-else class="total-item">
                  {{ row[column.prop] }}
                </p>
              </div>
            </template>
            <template v-slot:score="{ row }">
              <r-icon v-if="row.scoreEdit" name="edit-outline" right="5" color="var(--primary)" size="14"></r-icon>
              <span :style="{color:row.scoreStatusColor}">{{ row.score }}</span>
            </template>
          </r-table>
        </r-panel>
      </el-col>
    </el-row>
    <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    <approveDetail ref="approveDetail" @close="approveUpdate"></approveDetail>
    <taskDetail ref="taskDetail" @close="approveUpdate"></taskDetail>
    <el-dialog title="修改评分" :visible.sync="scoreDialogVisible" :close-on-click-modal="false"
      width="400px" append-to-body class="remark-dialog">
      <div class="main-color r-pb-20">
        当前任务：{{ scoreData.title }}
      </div>
      <el-form :model="scoreData" ref="formData" label-width="auto" size="small">
        <el-form-item label="任务评分" prop="remark">
          <el-input-number v-model="scoreData.score" :min="0" :precision="2"  :max="5" :controls="false" style="width:292px"></el-input-number>
        </el-form-item>
        <el-form-item :label="'备注'" prop="remark">
          <el-input type="textarea" :placeholder="'请填写备注'" v-model="scoreData.remark" maxlength="300"
            show-word-limit rows="4">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scoreDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleScoreChange" size="small" :disabled="scoreLoading" :loading="scoreLoading">确 定</el-button>
      </span>
    </el-dialog>
    <batch-edit ref="batchEdit" @success="batchEditSuccess"></batch-edit>
  </div>
</template>

<script>
import { uniq, isObject, cloneDeep, isObjectNone } from "@/utils";
import { getTaskListByAll, getTaskStatusEnums, taskDel, saveWeight,taskScore } from "@/api/approve";
import { list } from "@/api/indicator";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import approveDetail from "@/components/approve/createForm";
import taskDetail from '@/components/approve/detail/applyDetailDialog';
import batchEdit from "./components/batch-edit"
import moment from "moment";
const defaultQuery = {
  keyword: '',
  //负责人
  employee: [],
  //协作人
  collaborateEmployee: [],
  //负责部门
  department: [],
  //协作部门
  collaborateDepartment: [],
  orderBy: '',
  orderBySort: '',
  endDate: [],
  startDate: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')]
}
export default {
  components: {
    DepRoleModal,
    taskDetail,
    approveDetail,
    batchEdit
  },
  data() {
    const that = this;
    return {
      scoreLoading:false,
      scoreDialogVisible:false,
      type: 'dep',
      treeKeyword: '',
      areaTreeKeyword: '',
      isHideDep: false,
      treeData: [],
      areaTree: [],
      defaultExpandedKeys: [],
      defaultExpandedKeysBak: [],
      areaDefaultExpandedKeys: [],
      treeLoading: false,
      areaTreeLoading: false,
      department: "",
      currentNode: null,
      subDep: false,
      keyword: '',
      columns: [
        {type:'selection','reserve-selection':true,width:'80px',hide:!that.$has('task-delete'),selectable: (row, index) => {
          return (!!that.currentNode?.id && index != 0) || !that.currentNode?.id;
        }},
        {
          label: '任务名称',
          prop: 'name',
          "show-overflow-tooltip": true,
          "min-width": '250px'
        },
        {
          label: '负责人',
          prop: 'person.nickname',
          "show-overflow-tooltip": true,
          "min-width": '100px'
        },
        {
          label: '协作人',
          prop: 'collaborator',
          "show-overflow-tooltip": true,
          "min-width": '100px'
        },
        {
          label: '计划开始日期',
          prop: 'startDate',
          "show-overflow-tooltip": true,
          "min-width": '130px',
          sortable: 'custom'
        },
        {
          label: '计划结束日期',
          prop: 'endDate',
          "show-overflow-tooltip": true,
          "min-width": '130px',
          sortable: 'custom'
        }, {
          label: '任务状态',
          prop: 'statusCn',
          "show-overflow-tooltip": true,
          "min-width": '130px',
        }, {
          label: '当前流程人',
          prop: 'currentWorkflowUser',
          "show-overflow-tooltip": true,
          "min-width": '130px',
          sortable: 'custom'
        },
        {
          label: '评分',
          prop: 'score',
          "show-overflow-tooltip": true,
          "min-width": '110px',
          sortable: 'custom'
        }
      ],
      breadcrumb: [],
      swiperOptions: {
        freeMode: true,
        slidesPerView: 'auto'
      },
      swiper: null,
      query: cloneDeep(defaultQuery),
      searchQuery: cloneDeep(defaultQuery),
      popShow: false,
      modalConfig: {},
      dialogVisible: false,
      columnsComputeWay: {},
      statusList: [],
      nameProp: '',
      //当前选中项
      selection:[],
      delLoading:false,
      scoreCheck:['status_overdue_completion','status_completed','unable_complete','status_not_finished'],
      scoreData:{
        score:'',
        remark:'',
        title:''
      },
    }
  },
  filters: {
    noFilter(row) {
      return row?.noLink?.length > 0 ? row.noLink.join('.') : ''
    }
  },
  computed: {
    companyName() {
      return this.$store.state.userInfo?.company?.name || '';
    },
    searchActive() {
      return this.query.employee?.length > 0 || this.query.department?.length > 0 || this.query.collaborateEmployee?.length > 0 || this.query.collaborateDepartment?.length > 0 || this.query.startDate?.length > 0 || this.query.endDate?.length > 0;
    }
  },
  methods: {
    treeKeywordChange(val) {
      this.$refs.tree.filter(val);
    },
    handleCurrentChange(data, node) {
      this.expandedKeys = [data.id];
      this.currentNode = node.data;
      this.parentFilter(node);
      this.query.orderBy = '';
      this.query.orderBySort = '';
      this.$refs.table.refresh(1);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    //节点展开
    nodeExpand(data) {
      this.defaultExpandedKeysBak.push(data.id);
      this.defaultExpandedKeysBak = uniq(this.defaultExpandedKeysBak);
    },
    //节点关闭
    nodeCollapse(data) {
      this.defaultExpandedKeysBak = this.defaultExpandedKeysBak.filter((el) => {
        return el != data.id;
      });
    },
    getIndicatorList() {
      this.treeLoading = true;
      this.$refs.table.refresh(1);
      return list({ page: 1, pageSize: 9999,module:'filings' }).then(res => {
        this.treeData = res.data.list;
      })
        .then(res => {
          this.treeLoading = false;
        })
    },
    refresh() {
      this.$refs.table.refresh();
    },
    search() {
      this.$refs.table.refresh(1);
    },
    //员工列表请求
    loader(page, pageSize) {
      let options = this.getQuery();
      return getTaskListByAll({ page, pageSize, indicatorId: this.currentNode?.id || '', ...options,taskType:2 }).then(res => {
        this.columns = [{type:'selection','reserve-selection':true,width:'80px',hide:!this.$has('task-delete'),selectable: (row, index) => {
          return index !=0;
        }},...this.getTableColumn(res.data.tableHeader), { fixed: 'right', 'min-width': '100px', label: '操作', prop: 'handle' }];
        this.columns.forEach(el => {
          //只增不重置
          if (el.hasOwnProperty('computeWay') && !this.columnsComputeWay.hasOwnProperty(el.prop)) {
            this.columnsComputeWay[el.prop] = {
              field: el.prop,
              computeWay: el.computeWay,
              value: el.computeWay ? el.computeWay?.isTotal ? 'isTotal' : 'isAvg' : 'isTotal'
            }
          }
        });
        //此时有统计，提取出来
        if (res.data.list.length > 0 && res.data.list[0]?.isTotal) {
          res.data.list[0] = {
            ...res.data.list[0][0],
            isTotal: true
          }
        }
        return res.data;
      })
    },
    getTableColumn(list) {
      return list.map(el => {
        return el?.computeWay || el.field == 'weight' ? {
          prop: el.field,
          label: el.label,
          sortable: el.sortable ? 'custom' : null,
          'min-width': el['min-width'],
          computeWay: el.field == 'weight' ? { isTotal: 1 } : el?.computeWay,
          txt: el.txt || ''
        } : {
          prop: el.field,
          label: el.label,
          sortable: el.sortable ? 'custom' : null,
          'min-width': el['min-width'],
          txt: el.txt || ''
        }
      })
    },
    searchOpen() {
      this.$refs.search.open();
    },
    //提取父级
    parentFilter(node) {
      this.breadcrumb = [];
      this.breadcrumb.push(node);
      const traverse = (el) => {
        if (el.parent && isObject(el.parent.data)) {
          this.breadcrumb.unshift(el.parent);
          traverse(el.parent);
        }

        return !el;
      };

      traverse(node);

      this.$nextTick(() => {
        this.swiper.updateSlides();
      })
    },
    sortChange(order) {
      this.query = Object.assign(this.query, order);
      this.$nextTick(() => {
        this.search();
      })
    },
    breadcrumbClick(item, index) {
      if (index == this.breadcrumb.length - 1) return;
      this.breadcrumb.splice(index + 1, this.breadcrumb.length - 1 - index);
      this.currentNode = this.breadcrumb[this.breadcrumb.length - 1]?.data || null;
      this.$refs.tree.setCurrentKey(item);
      this.query.orderBy = '';
      this.query.orderBySort = '';
      this.$nextTick(() => {
        this.swiper.updateSlides();
        this.$refs.table.refresh(1);
      })
    },
    breadcrumbClear() {
      this.breadcrumb = [];
      this.currentNode = null;
      this.query.orderBy = '';
      this.query.orderBySort = '';
      this.$nextTick(() => {
        this.swiper.updateSlides();
        this.$refs.table.refresh(1);
      })
    },
    swiperInit() {
      this.swiper = new Swiper('#taskListSwiper', {
        freeMode: true,
        slidesPerView: 'auto',
        on: {
          resize: function () {
            this.update(); //窗口变化时，更新Swiper的一些属性，如宽高等
          },
        }
      });
    },
    popOpen() {
      this.popShow = !this.popShow;
      if (this.popShow) {
        this.searchQuery = cloneDeep(this.query);
      }
    },
    popHide() {
      this.popShow = false;
    },
    searchConfirm() {
      this.popHide();
      this.query = cloneDeep(this.searchQuery);
      this.search();
    },
    handleChangeSelect(selectType, title, model) {
      this.modalConfig = {
        type: 1,
        selectType,
        title,
        checkStrictly: true,
        model,
        checkList: this.searchQuery[model] || [],
      };
      this.dialogVisible = true;
    },
    handleEmployee(selectType, title, model) {
      this.popLock = true;
      this.modalConfig = {
        type: 1,
        selectType,
        title,
        checkStrictly: true,
        model,
        checkList: this.searchQuery[model] || [],
        isStaff: 1,
        onlyStaff: 1
      };
      this.dialogVisible = true;
    },
    handleConfirm(val) {
      this.searchQuery[this.modalConfig['model']] = val;
      this.dialogVisible = false;
    },
    computeWayChange(item, command) {
      this.$set(item, 'value', command);
      this.search();
    },
    //获取搜索参数
    getQuery() {
      let query = cloneDeep(this.query);
      let computeWay = [];
      if (this.currentNode?.type == 2) {
        Object.values(this.columnsComputeWay).forEach(el => {
          computeWay.push({
            compute: el.value,
            field: el.field
          })
        })
      }
      query.startDate = query.startDate ? query.startDate : ['', ''];
      query.endDate = query.endDate ? query.endDate : ['', ''];
      query.computeWay = computeWay;
      query.employee = query.employee.map(el => el.employeeId);
      query.collaborateEmployee = query.collaborateEmployee.map(el => el.employeeId);
      query.department = query.department.map(el => el.id);
      query.collaborateDepartment = query.collaborateDepartment.map(el => el.id);
      return query;
    },
    cellClassName({ row, column, columnIndex }) {
      //是否有权限修改评分
      let taskScoreEdit = this.$has('task-score-edit') && this.scoreCheck.includes(row.statusType);
      if (row.isTotal) {
        return column.type == 'selection' ? 'r-child-center total-row' : 'total-row'
      } else if(column.type == 'selection'){
        return 'r-child-center'
      }
      if(taskScoreEdit && column.property === 'score'){
        this.$set(row,'scoreEdit',true);
        return 'hover--opacity is-score'
      }else {
        return column.property === 'name' ? 'hover--opacity is-name' : column.property === 'weight' ? 'hover--opacity is-weight' : '';
      }
    },
    approveUpdate(isUpdate) {
      if (isUpdate) {
        this.$refs.table.refresh();
      }
    },
    detailOpen(row, column, cell) {
      let isName = cell.className.includes('is-name');
      let isWeight = cell.className.includes('is-weight');
      let isScore = cell.className.includes('is-score');
      //是否有权限修改评分
      let taskScoreEdit = this.$has('task-score-edit') && this.scoreCheck.includes(row.statusType);
      if (row.isTotal || (!isName && !isWeight && !isScore)) return;
      if (isName) {
        this.$refs.taskDetail.open(row.id);
      }else if(isScore){
        if(!taskScoreEdit) return;
        this.scoreData.taskId = row.id;
        this.scoreData.title = row.name;
        this.scoreData.remark = '';
        this.scoreData.score = parseFloat(row.score);
        this.scoreDialogVisible = true;
        console.log(this.scoreData)
      } else {
        this.$prompt('请输入一个范围为0~100的整数', '权重', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: row.weight,
          inputValidator: (val) => {
            let numReg = /^[0-9]+$/g;
            if (!numReg.test(val)) {
              return '权重只能是整数'
            }
            if (parseInt(val) < 0 || parseInt(val) > 100) {
              return '权重范围为0~100'
            }
            return true;
          },
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              if (row.weight === parseInt(instance.inputValue)) {
                this.$message.success('权重未发生更改');
                done();
                return;
              }
              saveWeight({
                taskId: row.id,
                weight: parseInt(instance.inputValue)
              }).then(() => {
                this.$message.success('权重修改成功');
                this.$refs.table.refresh();
                done();
              }).catch(() => { })
            } else {
              done();
            }
          }
        }).then(({ value }) => { }).catch(() => { });
      }
    },
    delTask(row) {
      taskDel({ taskId: row.id }).then(res => {
        this.$message.success('任务删除成功');
        this.$refs.table.refresh();
        this.$emit('refresh', 'detail');
      })
    },
    editTask(row) {
      this.$refs.approveDetail.handleOpen({
        id: row.id,
        title: '编辑任务',
        indicatorId: 0,
        task: true,
        filings:true
      });
    },
    selectionChange(selection){
      this.selection = selection;
    },
    batchDel(){
      let num = this.selection.length;
      if(!num){
        this.$message.error('至少选择一条任务');
        return;
      }
      this.$confirm(`确定要删除当前选中的${num}条任务吗？`, '提示', { type: 'warning' }).then(
        () => {
          this.delLoading = true;
          taskDel({ taskId: this.selection.map(el => el.id) }).then(res => {
            this.selection = [];
            //清空
            this.$refs.table?.clearSelection && this.$refs.table.clearSelection();
            this.$message.success(`已成功删除${num}条记录`);
            this.$refs.table.refresh(1);
            this.$emit('refresh', 'detail');
          })
          .finally(()=>{
            this.delLoading = false;
          })
        }
      )
    },
    batchEdit(){
      let num = this.selection.length;
      if(!num){
        this.$message.error('至少选择一条任务');
        return;
      }

      this.$refs.batchEdit.open(this.selection)
    },
    handleScoreChange() {
      if(this.scoreData.score < 0){
        this.$message.error('请输入一个大于或等于0且小于5的数字（可以是小数）');
        return;
      }
      this.scoreLoading = true;
      taskScore({
        ...this.scoreData
      }).then(res=>{
        this.$message.success(`评分修改成功`);
        this.$refs.table.refresh();
        this.scoreDialogVisible = false;
        this.scoreData = {
          score:'',
          remark:'',
          title:''
        }
      }).catch(err=>{
        console.log(err)
      })
      .finally(()=>{
        this.scoreLoading=false;
      })

    },
    batchEditSuccess(){
      this.selection = [];
      //清空
      this.$refs.table?.clearSelection && this.$refs.table.clearSelection();
      this.refresh();
    }
  },
  mounted() {
    this.getIndicatorList();
    this.swiperInit();
    getTaskStatusEnums({ type: 'all' }).then(res => {
      this.statusList = res.data;
    })
  },
  created() {
    if (!isObjectNone(this.$route.params)) {
      this.query = Object.assign(this.query, this.$route.params);
    }
  },
  beforeDestroy() {
    this.swiper.destroy();
  }
}
</script>

<style lang="scss" scoped>
.department {
  height: 100%;

  .fit {
    height: 100%;
  }

  .table-cover {
    flex: 2.2;
    width: 0;
  }

  ::v-deep .total-row {
    padding: 0;

    .cell {
      padding: 0;
    }
  }

  ::v-deep .total-item {
    padding: 20px 10px;
  }

  .sx-btn {
    padding: 5px 15px;

    &.active {
      border-color: var(--primary);
      color: var(--primary);
    }
  }

  ::v-deep .sub-dep {
    margin-left: 20px;

    .el-checkbox__label {
      padding-left: 4px;
    }
  }

  .custom-tree-node {
    flex: 1;
    font-size: 14px;
    padding-right: 8px;
  }

  .dep-num {
    background-color: #3f85f4;
    color: #ffffff;
    font-size: 12px;
    min-width: 34px;
    display: inline-block;
    height: 18px;
    text-align: center;
    line-height: 18px;
    margin: 0 6px;
    border-radius: 3px;
  }

  .swiper-slide {
    width: auto;
  }


  .custom-tree-btn {
    visibility: hidden;
    padding-left: 10px;

    .btn {
      font-size: 16px;
    }

    &.show {
      visibility: visible;
    }
  }

  .hide-icon {
    font-size: 20px;
  }

}
</style>