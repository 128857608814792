<template>
    <div>
        <r-drawer ref="rDialog" size="100%" direction="btt" dialog-title="人员统计" :footer="false" @close="close">
            <template v-slot:title>
                <div class="r-flex r-row-bet r-col-bottom" @click="popHide">
                    <div class="r-flex">
                        <p class="r-drawer-title">人员统计</p>
                        <div class="select-dep r-pl-20">
                            <r-tag-select v-model="query.department" class="r-w-max"
                            :collapseTags="true" placeholder="请选择人员部门"
                            @focus="handleChangeSelect('checkbox', '请选择人员部门', 'department')" size="small"
                            labelKey="name"
                            @remove-tag="search"></r-tag-select>
                        </div>
                    </div>
                    <div class="r-flex">
                        <p>模式选择：</p>
                        <div class="r-pr-20">
                            <el-button-group>
                                <el-button :type="query.type == 1 ? 'primary' : ''" size="mini" round
                                    @click="employeeIndicatorLoad(1)">指标负责人</el-button>
                                <el-button :type="query.type == 2 ? 'primary' : ''" size="mini" round
                                    @click="employeeIndicatorLoad(2)">指标分管领导</el-button>
                                <el-button :type="query.type == 3 ? 'primary' : ''" size="mini" round
                                    @click="employeeIndicatorLoad(3)">任务负责人</el-button>
                                <el-button :type="query.type == 4 ? 'primary' : ''" size="mini" round
                                    @click="employeeIndicatorLoad(4)">指标协作人</el-button>
                                <el-button :type="query.type == 5 ? 'primary' : ''" size="mini" round
                                    @click="employeeIndicatorLoad(5)">任务协作人</el-button>
                            </el-button-group>
                        </div>
                        <el-popover placement="bottom-end" width="500" trigger="manual" v-model="show"
                            @click.stop.native>
                            <div>
                                <div class="r-pb-20">筛选条件</div>
                                <el-form size="small" label-width="100px" label-position="left">
                                    <el-form-item label="计划开始时间">
                                        <div class="r-flex">
                                            <el-date-picker class="r-w-max" v-model="searchQuery.startDate"
                                                type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                                start-placeholder="开始日期" end-placeholder="结束日期">
                                            </el-date-picker>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="计划结束时间">
                                        <div class="r-flex">
                                            <el-date-picker class="r-w-max" v-model="searchQuery.endDate"
                                                type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                                start-placeholder="开始日期" end-placeholder="结束日期">
                                            </el-date-picker>
                                        </div>
                                    </el-form-item>
                                    <el-form-item>
                                        <div class="r-flex r-row-right">
                                            <el-button @click="popHide">取消</el-button>
                                            <el-button type="primary" @click="searchConfirm">确认</el-button>
                                        </div>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="r-mr-20 hover--main" slot="reference"  @click="popOpen" :class="{ 'main-color': searchActive }">
                                <r-icon name="shaixuan" type="task" size="26"></r-icon>
                            </div>
                        </el-popover>
                        <el-button size="mini" type="primary" style="margin-right: 10px;" @click="exportData()">导出</el-button>
                    </div>
                </div>
            </template>
            <div class="top-table-container" @click="popHide">
                <r-table ref="table" :loader="loader" :columns="columns" height="100%" row-key="id"
                    @sortChange="sortChange">
                    <template v-slot:statusRatio1="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio2="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio3="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio4="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio5="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:progress="{ row, column }">
                        <span :style="{color:parseFloat(row[column.prop]) >= warningValue? 'green' :'red'}">{{ row[column.prop] }}%</span>
                    </template>
                </r-table>
            </div>
        </r-drawer>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    </div>
</template>
<script>
import { employeeIndicatorStatistic } from '@/api/statistics';
import fileDownload from "js-file-download";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import { cloneDeep } from 'lodash';
import moment from 'moment';
const employeeForm = {
    startDate: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
    endDate: ['', '']
};
export default {
    components:{DepRoleModal},
    props:{
        warningValue:{
            type:Number,
            default:0
        }
    },
    data() {
        return {
            query: {
                type:1,
                department:[],
                ...employeeForm
            },
            searchQuery:{
                type:1,
                department:[],
                ...employeeForm
            },
            modalConfig:{},
            dialogVisible:false,
            show:false
        }
    },
    computed:{
        columns(){
            return [
                {
                    label: '人员',
                    prop: 'nickname',
                    "min-width": '120px',
                    "show-overflow-tooltip": true
                },{
                    label: '部门',
                    prop: 'departmentName',
                    "min-width": '140px',
                    "show-overflow-tooltip": true
                }, 
                {
                    label:this.query.type == 4 ? '协作指标总数' : this.query.type == 3 ? '负责任务总数' :  this.query.type == 5 ? '协作任务总数' : '负责指标总数',
                    prop: 'totalCount',
                    sortable: 'custom',
                    'min-width': "140px"
                }, 
                {
                    label: [3,5].includes(this.query.type) ? '任务数占比' : '指标数占比',
                    prop: 'totalCountRatio',
                    sortable: 'custom',
                    'min-width': "140px"
                }, 
                {
                    label: '未启动',
                    prop: 'status1',
                    sortable: 'custom',
                    'min-width': "140px"
                }, 
                {
                    label: [3,5].includes(this.query.type) ? '进行中' : '正常进行',
                    prop: 'status2',
                    sortable: 'custom',
                    'min-width': "120px"
                },  
                {
                    label: [3,5].includes(this.query.type) ? '已逾期' : '进行缓慢',
                    prop: 'status3',
                    sortable: 'custom',
                    'min-width': "120px"
                }, 
                {
                    label: '已完成',
                    prop: 'status4',
                    sortable: 'custom',
                    'min-width': "120px"
                },
                {
                    label: '未完成',
                    prop: 'status5',
                    sortable: 'custom',
                    'min-width': "120px"
                },
                {
                    label: '已得分值总和',
                    prop: 'score',
                    sortable: 'custom',
                    'min-width': "140px"
                },
                {
                    label: '应得分值总和',
                    prop: 'totalScore',
                    sortable: 'custom',
                    'min-width': "140px"
                },
                {
                    label: '综合进度',
                    prop: 'progress',
                    sortable: 'custom',
                    'min-width': "120px"
                },
            ]
        },
        searchActive() {
            return (this.query.startDate || []).some(el => el) || (this.query.endDate || []).some(el => el);
        },
    },
    methods: {
        exportData() {
          let form = this.getFormData();
          employeeIndicatorStatistic({ page: 1, pageSize: 9999999, ...form, export: 1  }).then(res => {
            fileDownload(res, "人员进度排行.xlsx");
          })
        },
        open(form = null) {
            if (form) {
                this.query = Object.assign(this.query, form);
            }
            this.$refs.rDialog.handleOpen();
        },
        search() {
            this.$refs.table.refresh(1);
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.search();
            })
        },
        loader(page, pageSize) {
            let form = this.getFormData();
            return employeeIndicatorStatistic({ page, pageSize, ...form }).then(res => {
                return res.data;
            })
        },
        employeeIndicatorLoad(type){
            this.query.type = type;
            this.search();
        },
        handleChangeSelect(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.query[model] || [],
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this.query[this.modalConfig['model']] = val;
            this.dialogVisible = false;

            this.search();
        },
        getFormData(){
            let form = cloneDeep(this.query);
            form.department = form.department.map(item => item.id);
            return form;
        },
        popOpen(){
            if(this.show){
                this.popHide();
                return;
            }
            this.searchQuery = cloneDeep(this.query);
            this.show = true;
        },
        popHide(){
            this.show = false;
        },
        searchConfirm(){
            this.query = cloneDeep(this.searchQuery);
            this.show = false;
            this.search();
        },
        close(){
            this.show = false;
        }
    }


}
</script>

<style lang="scss">
.top-table-container {
    height: 100%;
}
</style>