<template>
    <r-drawer ref="rDialog" :dialogTitle="title" size="620px" @close="reset" @submit="submit">
        <div class="dialog-content">
            <el-form size="small" label-position="top" :rules="rules" :model="form" ref="form">
                <el-form-item prop="pid" label="上级分类">
                   <el-cascader
                    v-model="form.pid"
                    :options="options" placeholder="请选择上级分类" class="r-w-max" :props="{emitPath:true,value:'id',label:'title',checkStrictly:true}" @change="cChange"></el-cascader>
                </el-form-item>    
                <el-form-item prop="title" label="分类名称">
                   <el-input v-model="form.title" placeholder="请输入分类名称"></el-input>
                </el-form-item>
                <el-form-item label="默认负责部门">
                    <div class="r-flex">
                        <div class="r-flex-1 r-mr-10">
                            <el-select v-model="form.responsibleDepartmentType" class="r-w-max">
                                <el-option v-for="item in departmentType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="r-flex-1" v-if="form.responsibleDepartmentType == 'department_type_appoint'">
                            <r-tag-select v-model="form.responsibleDepartmentId" labelKey="name" placeholder="请选择负责部门"
                            max @focus="handleChangeSelect('radio', '请选择负责部门', 'responsibleDepartmentId')"></r-tag-select>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="默认负责人">
                    <div class="r-flex">
                        <div class="r-flex-1 r-mr-10">
                            <el-select v-model="form.responsibleEmployeeType" class="r-w-max">
                                <el-option v-for="item in employeeType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="r-flex-1" v-if="form.responsibleEmployeeType == 'employee_type_appoint'">
                            <r-tag-select v-model="form.responsibleEmployeePostId" labelKey="name" placeholder="请选择人员"
                            max @focus="handleChangeSelect('radio', '请选择人员', 'responsibleEmployeePostId', 1 , 1)"></r-tag-select>
                        </div>
                        <div class="r-flex-1" v-if="form.responsibleEmployeeType == 'employee_type_post'">
                            <el-select v-model="form.post" class="r-w-max" filterable clearable placeholder="请选择职位">
                                <el-option v-for="item in postList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="默认整改期限">
                    <div class="r-flex">
                        <div class="r-flex-1 r-mr-10">
                            <el-select v-model="form.termType" class="r-w-max">
                                <el-option v-for="item in termType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="r-flex-1" v-if="form.termType == 'term_type_day'">
                            <el-input-number :controls="false" v-model="form.termDay" class="r-w-max" placeholder="请填写具体天数，最少为4天" :min="4"></el-input-number>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="默认扣分及节点">
                    <div class="r-flex r-row-bet">
                        <div class="r-flex tips r-pl-10">
                            发现问题扣<el-input-number :min="0" :precision="2" v-model="form.points" class="r-ml-10 r-mr-10" :controls="false"></el-input-number>分
                        </div>
                        <el-button icon="el-icon-plus" type="primary" @click="addNode">添加节点</el-button>
                    </div>
                    <div class="nodes">
                        <div class="node r-border r-flex r-row-bet" v-for="(node,index) in form.categoryPoints" :key="node.gid">
                            <div>
                                整体期限第<el-input-number :min="1" v-model="form.denominator" class="r-ml-10 r-mr-10 number" :controls="false"></el-input-number>分之<el-input-number :min="index == 0 ? 1 : form.categoryPoints[index-1].numerator+1" v-model="node.numerator" class="r-ml-10 r-mr-10 number" :controls="false" :max="form.denominator"></el-input-number>天前整改得<el-input-number :min="0" v-model="node.points" class="r-ml-10 r-mr-10 number" :controls="false" :precision="2" style="width:80px"></el-input-number>分
                            </div>
                            <div class="close" @click="closeNode(node.gid)">
                                <i class="el-icon-remove-outline danger-color hover--opacity"></i>
                            </div>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
        </div>
    </r-drawer>
</template>
<script>
import {questionCategorySave,questionCategoryList,questionCategoryOptions} from "@/api/question";
import { cloneDeep,isArray,guid } from "@/utils";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import { getPostListByTree } from "@/api/post";
const defaultForm = {
    pid:'',
    title:'',
    responsibleDepartmentType:'department_type_appoint',
    responsibleDepartmentId:[],
    responsibleEmployeeType:'employee_type_appoint',
    responsibleEmployeePostId:[],
    post:'',
    termType:'term_type_day',
    termDay:4,
    categoryPoints:[],
    points:0,
    denominator:1
}
export default {
    components:{
        DepRoleModal
    },
    data() {
        return {
            form: cloneDeep(defaultForm),
            title:'',
            rules:{
                title:[
                    { required: true, message: '请输入分类名称', trigger: ['change','blur'] }
                ]
            },
            options:[],
            modalConfig: {},
            dialogVisible:false,
            postList:[],
            departmentType:[],
            employeeType:[],
            termType:[]
        }
    },
    methods: {
        open(form=null,title='新建问题分类') {
            this.title = title;
            if(form){
                let _form = this.initData(cloneDeep(form));
                _form.pid = form.pid == '00000000-0000-0000-0000-000000000000' ? '' : _form.pid;
                this.form = Object.assign(cloneDeep(defaultForm),_form);
            }
            this.getList();
            this.getPostList();
            this.$refs.rDialog.handleOpen();
            questionCategoryOptions().then(res=>{
                let {departmentType,employeeType,termType} = res.data;
                this.departmentType = departmentType;
                this.employeeType = employeeType;
                this.termType = termType;
                //新建的时候填充
                if(!form){
                    this.form.responsibleDepartmentType = 1;
                    this.form.responsibleEmployeeType = 1;
                    this.form.termType = 3;
                }
            })
        },
        getList(){
            questionCategoryList().then(res => {
                this.options = res.data;
            })
        },
        reset(){
            this.form = cloneDeep(defaultForm);
        },
        submit(done){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let form = this.getFormData();
                    questionCategorySave(form).then(res=>{
                        this.$message.success('提交成功');
                        done(true);
                        this.$emit('confirm');
                    })
                    .catch(err=>{
                        done(false);
                    })
                } else {
                    done(false);
                }
            });
        },
        cChange(val){
            console.log(val)
        },
        handleChangeSelect(selectType, title, model,type = 1,isStaff = 0) {
            this.modalConfig = {
                type,
                selectType,
                title,
                checkStrictly: true,
                model,
                isStaff,
                onlyStaff:isStaff,
                checkList: this.form[model] || [],
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this.form[this.modalConfig['model']] = val;
            this.dialogVisible = false;
        },
        getPostList(){
            getPostListByTree().then(res=>{
                this.postList = res.data.list;
            })
        },
        addNode(){
            if(this.form.categoryPoints.length){
                this.form.categoryPoints.push({
                    gid:guid(),
                    numerator:this.form.categoryPoints[this.form.categoryPoints.length-1].numerator+1,
                    points:''
                })
            }else{
                this.form.categoryPoints.push({
                    gid:guid(),
                    numerator:1,
                    points:''
                })
            }
        },
        closeNode(gid){
            this.form.categoryPoints = this.form.categoryPoints.filter(item=>item.gid!=gid)
        },
        getFormData(){
            let form = cloneDeep(this.form);
            if(isArray(form.pid)){
                form.pid = form.pid[form.pid.length-1];
            }
            //当选择发起人所在部门时清空掉部门选择
            if(form.responsibleDepartmentType == 'department_type_appoint'){
                form.responsibleDepartmentId = form.responsibleDepartmentId[0]?.id || '00000000-0000-0000-0000-000000000000';
            }else{
                form.responsibleDepartmentId = '00000000-0000-0000-0000-000000000000';
            }
            //根据默认负责人选项填充数据
            if(form.responsibleEmployeeType == 'employee_type_appoint'){
                form.responsibleEmployeePostId = form.responsibleEmployeePostId[0]?.id || '00000000-0000-0000-0000-000000000000';
            }else{
                form.responsibleEmployeePostId = form.post || '00000000-0000-0000-0000-000000000000';
            }
            //根据扣分节点生成数据
            form.categoryPoints = form.categoryPoints.map(item=>{
                return {
                    denominator:form.denominator,
                    numerator:item.numerator,
                    points:item.points
                }
            });
            //删除掉无关数据
            delete form.denominator;
            delete form.post;
            return form;
        },
        //处理数据
        initData(form){
            if(!form.id){
                return form;
            }
            if(form.categoryPoints.length){
                form.denominator = form.categoryPoints[0].denominator;
            }
            //部门
            if(form.responsibleDepartmentId && form.responsibleDepartmentId != '00000000-0000-0000-0000-000000000000'){
                if(form.responsibleDepartmentType == 'department_type_appoint'){
                    form.responsibleDepartmentId = [{
                        id:form.responsibleDepartmentId,
                        name:form.responsibleDepartmentName
                    }]
                }else{
                    form.responsibleDepartmentId = []
                }
            }else{
                form.responsibleDepartmentId = []
            }
            //人员
            if(form.responsibleEmployeePostId && form.responsibleEmployeePostId != '00000000-0000-0000-0000-000000000000'){
                if(form.responsibleEmployeeType == 'employee_type_appoint'){
                    form.responsibleEmployeePostId = [{
                        name:form.responsibleEmployeeName,
                        id:form.responsibleEmployeePostId
                    }]
                }else{
                    form.post = form.responsibleEmployeePostId;
                    form.responsibleEmployeePostId = [];
                }
            }else{
                form.responsibleEmployeePostId = []
            }
            return form;
        }
    }



}
</script>

<style lang="scss" scoped>
.dialog-content {
    height: 250px;
    .tips,.node{
        font-size:13px;
        .number{
            width: 60px;
        }
    }
    .node{
        padding: 10px;
        border:1px solid #e5e5e5;
        border-radius: 6px;
        margin-top:10px;
        &:first-child{
            margin-top:20px;
        }
        .close{
            font-size:28px;
        }
    }
}
</style>