<template>
    <div class="form-radio-wrapper">
        <el-cascader
          filterable
          :placeholder="placeholder+'，可搜索'"
          v-model="selectValue"
          :options="options"
          ref="cascader"
          :props="{label:'name',value:'id',checkStrictly:true,emitPath:false}"
          :disabled="disabled" @change="change" clearable></el-cascader>
    </div>
  </template>
  <script>
  import {isArray,isObject} from "@/utils";
  import {getCityList} from "@/api/common";
  export default {
    props: ['value', 'formData'],
    inject: ['root'],
    data(){
      return {
        loading:false,
        options:[]
      }
    },
    computed: {
      selectValue: {
        get() {
          return this.value
        },
        set(val) {
          return this.$emit('input', val);
        }
      },
      placeholder() {
        return this.formData?.placeholder || '请选择'
      },
      disabled() {
        return !!this.formData?.config?.expandParams?.disabled;
      },
      employeeVillage(){
        return this.$store.state?.userInfo?.employee?.area;
      }
    },
    mounted() {
      this.remoteMethod();
    },
    methods: {
      //动态拉取数据，目前仅支持借款套件中的分销商
      remoteMethod(){
        getCityList().then(res=>{
            this.options = res.data;
            this.selectValue = '073c73bb-7334-419e-8c97-323a87fda5be';
            if(this.$route.name == 'approve-apply' && this.employeeVillage?.id){
              this.selectValue = this.employeeVillage?.id;
              this.$nextTick(()=>{
                this.change(this.selectValue)
              })
            }
        })
      },
      change(val){
        let path = this.$refs.cascader.getCheckedNodes();
        //记录完整路径
        if(path.length>0){
          this.formData.config.expandParams.villagePath = path[0].pathNodes.map(item=>{
            return {
              label:item.label,
              value:item.value
            }
        })
        }else{
          this.formData.config.expandParams.villagePath = [];
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  ::v-deep .el-select {
    width: 100%;
  }
  
  ::v-deep .el-cascader {
    width: 100%;
  }
  .el-select-dropdown__item.more-label{
    height:auto;
    line-height:1;
    padding: 0 20px 10px;
    p:first-child{
      height:34px;
      line-height:34px;
    }
    p:last-child{
      font-size:12px;
      color:#409EFF;
    }
  }
  </style>
  